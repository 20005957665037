import React from 'react';

import { useQuery } from 'react-query';
import { fetchProductModel } from 'data/product-model-service';

export const ProductModelContext = React.createContext({
  status: 'loading',
  products: null
});

export const useProductModelContext = () => React.useContext(ProductModelContext);

export const ProductModelProvider = ({ children }) => {
  const { data, status } = useQuery('productModel', fetchProductModel);
  const productModel = {
    status,
    products: data
  };
  return (
    <ProductModelContext.Provider value={productModel}>
      {children}
    </ProductModelContext.Provider>
  );
};
